import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesGuilo: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide '} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Guillotine: Winter Slayer guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_xguilo.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Guillotine: Winter Slayer Guide & Review</h1>
          <h2>
            A guide & review for Guillotine: Winter Slayer in NIKKE: Goddess of
            Victory.
          </h2>
          <p>
            Last updated: <strong>14/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Guillotine: Winter Slayer" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="guillotine-winter-slayer"
                  enablePopover
                />
              </div>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                XGuillo is a sufficient damage dealer, but in 2024, just
                "sufficient" is not enough. She has a comprehensive kit — full
                of ATK, Elemental DMG, and ATK DMG — making it seem as if she
                were complete. But, behind her huge numbers lies a deceivingly
                low multiplier. What's the point of a multitude of gigantic
                buffs if she is not dealing any damage in the first place?
              </p>
              <p>
                XGuillo's kit lacks true character. She as a DPS is mediocre but
                she as a support is also mediocre — something that falls between
                the lines of "not weak enough to be dumped" but also "not strong
                enough to be worth pulling or developing". She, alongside SMary,
                is one of the only few units who can increase Water allies'
                Elemental DMG. She can also grant them ATK & ATK DMG, but that
                is the farthest she can go. A part of it is locked behind Burst
                Skill, which means to benefit from it, you may have to waste
                another teammate's crucial Burst Skill. While Elemental DMG is a
                valuable buff, we do not have any hyper-carry Water DPS who can
                make use of it yet.
              </p>
              <p>
                This restricts XGuillo to just one or at most two regions of the
                game, one being against Harvester in Anomaly Interception and
                the other being in Water-weak Raid as an auxiliary DPS and
                support in Team 3/4. The former is overshadowed by the
                field-tested XLud + XQuency + Naga combo that has already proven
                to reach S9. The latter is something hypothetical but the chance
                is medium-high. Her usage should rise with the release of a
                powerful Water DPS who can make use of her buffs.
              </p>
              <p>
                Now, outside these PvE contents, she is utterly useless, not
                because she is bad, but because she is outclassed by a ton of
                other units. She is fine as an alternative but don't expect
                greatness from someone who needs at least 25 seconds to fully
                stack up her ATK. Short battles are not her forte. This includes
                PvP, where she is illusionary. Zero impact, meaningless buffs,
                and the fact that enemies don't have core in PvP collapses her
                ratings.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                Probably not. If you are an F2P or someone who is short on
                resources, you can freely skip this unit. Even if the next DPS
                is a Water Pilgrim with insanely broken skills, there must be
                other ways to support them without needing XGuillo. XGuillo will
                also rerun next year in the same season, so if you like the
                character, you can save up beforehand. But, right now,{' '}
                <strong>
                  what's important is having enough funds for the New Year Nikke
                </strong>
                .
              </p>
              <p>
                If you are a tryhard Solo Raider, then the answer changes to
                yes. XGuillo might not be your ordinary shimmery unit. She
                exists and she is useful for what she is made for. Her niche
                skills might allow her to stay relevant in Water-weak Raids for
                a long time, especially once we get a strong Water unit. Will
                that be on New Year? Who knows?
              </p>
              <p>
                But, if you want to be mindful, you can wait until the kit for
                the New Year unit is revealed, then re-decide whether you want
                to get XGuillo. We don't usually recommend this because we want
                to maximize profit by using the unit in their respective raid,
                but this time it's different, since the element favors xMaiden
                instead of xGuillo..
              </p>
              <p>
                Dupes are not necessary for a weak unit like her, and using
                mileage is discouraged. However, you may MLB her if you try hard
                for Solo Raids.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <StaticImage
                src="../../../images/nikke/reviews/xguilo_guide_1.webp"
                alt="Kit"
              />
              <p>
                Guillotine: Winter Slayer's kit revolves around gaining EXP,
                leveling up, and unlocking buffs for herself and Water allies.
                To gain EXP, she will need to land shots, and the rate is
                doubled when hitting cores. Every time she levels up, she also
                strengthens her Burst Skill, allowing her to deal slightly more
                damage for each level obtained. Her level caps at 11, after
                which she is no more than a sitting duck.
              </p>
              <p>
                XGuillo wields an ordinary AR that deals 13.65% damage per shot,
                and likewise, shoots 12 times per second in 60 FPS and 10 times
                per second in 30 FPS. This means she will take at least ~25
                seconds of infinite shooting to reach maximum level. There is
                nothing special about her weapon except for the fact that she
                reloads pretty fast (@ 1s).
              </p>
              <h5>Why Must Her Level Be Capped?</h5>
              <blockquote>
                <p>■ Activates every time EXP stacks 10. Affects self.</p>
                <p>Hero Level Up: Reaches a maximum of Level 11.</p>
                <p>Hero Level Up Reward: Reloads 10.26%.</p>
                <p>
                  Hero Level Up Reward: Recovers 2.44% of caster's final Max HP
                </p>
                <p>
                  ■ Activates when Hero levels up. Affects all Water Code
                  allies.
                </p>
                <p>
                  Damage as strong element ▲ 1.16% * Hero Level continuously.
                </p>
                <p>ATK ▲ 0.91% of caster's ATK * Hero Level continuously.</p>
              </blockquote>
              <p>
                Whenever XGuillo gains 10 EXP stacks, she advances a level and
                unlocks additional buffs for her and her teammates. She starts
                at Level 1 and grows to a maximum of Level 11. Every time she
                levels up, she:
              </p>
              <ul>
                <li>
                  <strong>Reloads 10.26% of max ammo</strong>: The more XGuillo
                  fires, the higher her uptime, increasing output and
                  accelerating level gain. Synergizes with Max Ammo and Bastion
                  really well and potentially allows for infinite shooting when
                  hitting a core.
                </li>
                <li>
                  <strong>Recovers 2.44% of her Max HP</strong>: The more
                  XGuillo shoots, the more she heals herself. A built-in
                  self-heal vital for surviving longer fights. Having regular
                  self-heal, as seen in Asuka & Alice, allows for some
                  adventurous comps.
                </li>
              </ul>
              <Alert variant="primary">
                <p>
                  Although these skills are listed as level-up rewards, they can
                  be continuously triggered even after XGuillo reaches maximum
                  level!
                </p>
              </Alert>
              <p>
                Additionally, as soon as XGuillo levels up at least once, she
                will start buffing Water code allies,{' '}
                <strong>including herself</strong>, with:
              </p>
              <ul>
                <li>
                  <strong>
                    Elemental DMG▲ equal to 1.16% × Hero Level (max. 12.76%)
                  </strong>
                  : Pretty good value; slightly stronger than half an average
                  ELE DMG OL roll. The benefit will be more felt if XGuillo is
                  paired with a stronger Water DPS.
                </li>
                <li>
                  <strong>
                    ATK▲ equal to 0.91% of caster's ATK × Hero Level (max.
                    10.01%)
                  </strong>
                  : Decent value since she is an Attacker, and it's permanent. A
                  good generic damage buff for applicable units. Nothing
                  special, however.
                </li>
              </ul>
              <h5>We Just Hope There is a Core </h5>
              <blockquote>
                <p>
                  ■ Activates after landing 6 normal attack(s) without hitting
                  the core. Affects self.
                </p>
                <p>EXP: ATK ▲ 1.81%, stacks up to 100 time(s) continuously.</p>
                <p>
                  ■ Activates when hitting the Core for 3 time(s). Affects self.
                </p>
                <p>EXP: ATK ▲ 1.81%, stacks up to 100 time(s) continuously.</p>
                <p>■ Activates when Hero Level is 2 or above. Affects self.</p>
                <p>Damage as strong element ▲ 7.46% continuously.</p>
              </blockquote>
              <p>
                Every time XGuillo lands either 6 shots outside a core or 3
                shots on a core (independent of each other), she gains 1 EXP
                stack, up to 100 times. Each grants her ATK▲ 1.81%, peaking at
                181%▲ with 100 stacks. Stacks denote her level:
              </p>
              <ul>
                <li>0 Stacks: Level 1</li>
                <li>10 Stacks: Level 2</li>
                <li>20 Stacks: Level 3</li>
                <li>And so on...</li>
              </ul>
              <p>
                Do note that the EXP stacks for core hits and non-core hits are
                shared; it's just that there are two ways of stacking them.
                Hitting a core accumulates EXP 2× faster, so always aim at the
                core!
              </p>
              <Alert variant="primary">
                <p>
                  Do note that, if you use the AUTO-AIM function, all Nikke(s)
                  will lose some hitrate, and that also means XGuillo may take
                  more time to stack due to her potentially missing core.
                </p>
              </Alert>
              <p>
                The big numbers are deceiving. While it is good for pushing
                high-deficit due to the ATK-DEF formula, XGuillo just doesn't
                have damage. Her basic attack multiplier is weak, and her Burst
                Skill is hilarious and just does not have enough impact
                (negligible burst damage). Much worse, this also means{' '}
                <strong>she doesn’t scale that well with ATK buffs</strong>,
                which are the most common, and that means{' '}
                <strong>she scales weaker with OL</strong> compared to regular
                units!
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Bunny
                  Alice's Time?
                </h6>
                <p>
                  Stack refreshers allow XGuillo to obtain EXP and level up
                  faster, reaching maximum buffs earlier, but it's not worth it
                  because she can stack it fast enough herself and Bunny Alice
                  is a slot pressure.
                </p>
              </blockquote>
              <p>
                Additionally, whenever XGuillo is Level 2 or higher, she will
                continually buff herself with Elemental DMG▲ 7.46%. Pretty meh
                value, but when combined with S1, that is one free built-in
                Elemental DMG for her. But, isn't her support skill what makes
                her worthwhile?
              </p>
              <h5>A Gift to All Water Code Allies</h5>
              <blockquote>
                <p>■ Affects all Water Code allies.</p>
                <p>Attack Damage ▲ 10.14% for 10 sec.</p>
                <p>Damage as strong element ▲ 18.75% for 10 sec.</p>
                <p>■ Affects 1 enemy unit(s) with the highest Max HP.</p>
                <p>
                  Deals continuous damage equal to 20.87% of the final ATK *
                  Hero Level every sec for 10 sec.
                </p>
              </blockquote>
              <p>
                XGuillo’s Burst Skill temporarily enhances the ATK Damage and
                ELE DMG of all Water code allies,{' '}
                <strong>including herself</strong>, and launches a series of
                attacks <strong>at one enemy with the Highest Max HP</strong>{' '}
                over 10 seconds. This nuke is a major source of xGuillotine’s
                overall damage. The strength of this nuke depends on the Hero
                Level stacks, which means that xGuillotine purely is a Bossing
                unit, since all of her DPS sources take time to ramp up to their
                max potential.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="b">B</strong> | Campaign (HD)
                - <strong className="c">C</strong>
              </h5>
              <p>
                xGuillotine has severe problems when it comes to Campaign-type
                stages. We can shorten the major problems down to 3 distinct
                points, and they apply to both Low Deficit and High Deficit
                gameplay. First and foremost, xGuillotine’s DPS output is too
                low. Not only does she requires a lot of time for her self buffs
                and team buffs to stack up (almost 1 minute to fully stack up
                these buffs), but even after she fully stacks these buffs, she
                is unable to compete with meta Single Target DPS such as
                xLudmilla, Quency:EQ, Cinderella, etc. She is simply a worse
                damage dealer than them.
              </p>
              <p>
                Her second major problem lies in the fact that she has no AoE
                damage, at all. She cannot pierce, she cannot deal splash damage
                since she wields an AR, not an RL, and she does not possess any
                AoE Burst or Skills at all. This is honestly a huge let-down for
                Campaign, since it prefers
              </p>
              <p>
                Finally, her team buffs have a bunch of problems. Firstly, they
                are locked only for Water Code units. Secondly, half of those
                buffs are ‘Elemental Damage’ buffs, so they only apply 1/5th of
                the time (actually, the statistics are even worse for this since
                Water Weak mob stages are the least seen mob stages in
                Campaign). Furthermore, not only are these buffs of low value,
                but they also take a long while to fully stack, so for 2/3rds of
                the fight you are actually getting weaker buffs than what was
                advertised. Not only that, but half of these buffs come during
                her own Burst! This is bad since DPS units usually deal damage
                during their own Bursts.
              </p>
              <p>
                While these ele buffs are appreciated on boss stages, they are
                quite disappointing for Campaign most of the time. Only a
                handful of useful Water Code DPS exist in Campaign anyways,
                namely xLudmilla, Emilia, Phantom and Quency:EQ. None of them
                are insanely good Campaign units like Red Hood or Alice, who can
                win stages by themselves. This further makes xGuillotine a less
                usable unit.
              </p>
              <p>
                As for her use, we feel that it is heavily restricted to Elysion
                Tower only in the overall meta, probably as a direct replacement
                to her normal version if anything. Guillotine is a single target
                DPS unit as well, but she requires low HP to work. In the
                overall tryhard meta, Guillotine should still be better than
                xGuillotine. For casual gameplay, however, we can see
                xGuillotine being a suitable replacement, even as an off-burst
                unit.
              </p>
              <h5>
                Bossing - <strong className="a">A</strong>
              </h5>
              <p>
                This is the only place which allows xGuillotine to shine without
                much complaints. When it comes to her DPS, it can rival Scarlet
                directly since she is essentially an equal DPS to her. However,
                this considers Scarlet outside of her optimal team (without
                S.Anis). Hence, xGuillotine is, in reality, a worse DPS than
                Scarlet on neutral element, and hence cannot be considered a
                meta DPS unit, but a tier lower than that.
              </p>
              <p>
                In her own element however, she is able to beat Scarlet and a
                few other DPS units. Due to some extremely strong releases
                recently (such as Cinderella), you might think that her spot is
                in trouble. However, she still is usable as a team 5 unit on her
                own Raid. The best comparison of her is to 2B, who also comes
                around and gives a cameo everytime a Fire Raid pops up.
                Similarly, xGuillotine is expected to be used on Water Weak
                Raids.
              </p>
              <p>
                Her best use is alongside Water Code units, since you want to
                maximise the use of her kit. We recommend using her alongside
                xLudmilla or Quency:EQ, since both of them still deal decent
                damage outside of their Burst as well. This synergizes well with
                xGuillotine’s buffs, since half of them are provided during
                xGuillo’s own Burst.
              </p>
              <h5>
                PVP - <strong className="d">D</strong>
              </h5>
              <p>
                xGuillotine provides no special addition in PvP. She has minimal
                burst generation, her DPS output is too low (inside and outside
                of burst), since her buffs take too long to stack. Even if they
                were to stack, xGuillo would at best be a single target DPS,
                which are not highly appreciated in PvP. To top it all off,
                xGuillo is a water element unit. In a field dominated by
                electric element units, she will not be able to fare well, or
                essentially survive, at all. We highly recommend not using
                xGuillotine in PvP.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                While XGuillo already has too much ATK, there is really nothing
                better than ELE, ATK, and Max Ammo, the standards of DPS OL. Hit
                Rate is useful as it allows XGuillo to land more core shots but
                the gain is still negligible. Moreover, there are certain
                imaginary breakpoints that you can achieve with Max Ammo to
                maximize the return value from S1’s Instant Reload. So, here we
                go:
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong>
                </li>
                <li>
                  <strong>Ideal:</strong> 4× ELE, 2× Max Ammo, 4× ATK
                </li>
                <li>
                  <strong>Passable:</strong> 2× Hit Rate, 2× Max Ammo
                </li>
                <li>
                  <strong>Priority:</strong> Low (PvE)
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <p>
                Before we explain, we would like to remind you the xGuillotine
                is a low priority unit to invest into. We recommend this
                investment only to those players who are focusing on getting
                super high scores in Solo Raid, or to those who are fans of
                xGuillotine.
              </p>
              <ul>
                <li>
                  <strong>Skill 1: 4/7/10 </strong>
                </li>
                <ul>
                  <li>
                    All of Guillotine’s skills provide damage buffs. This skill
                    specifically provides both self buffs and team buffs. If
                    xGuillo does not have good OL gear lines, she is more of a
                    support. In this case, upgrade this skill before Skill 2.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 4/7/10 </strong>
                </li>
                <ul>
                  <li>
                    This skill specifically provides self buffs for xGuillotine.
                    This skill is of a lower priority to upgrade compared to
                    Skill 1 but only if xGuillo does not have good OL gear
                    lines. If she does have good OL lines (2-3 ele line and 2-3
                    atk lines), then this skill is of higher priority to upgrade
                    compared to Skill 1.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 4/7/10 </strong>
                </li>
                <ul>
                  <li>
                    This is both a supportive skill as well as self buff skill.
                    Upgrade this as high priority if you plan to use xGuillo
                    alongside water units, since it provides both a high damage
                    nuke as well as team buffs.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="guillotine-winter-slayer" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                XGuillo best uses Bastion outside Reload Speed comps, but when
                there is a Reload Speed buffer in the team, Resilience is the
                most optimal choice.
              </p>
              <h5>Doll Investments</h5>
              <p>
                Currently, XGuillo does not have too much PvE presence and is
                also nonexistent in PvP to warrant doll-ing her beyond SR 0 or
                at most SR 5.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Water Showdown </h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="ludmilla-winter-owner"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="guillotine-winter-slayer"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="naga" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Possibly the strongest team for Harvester, but may depend on
                your investments! Previously, the strongest team was considered
                to be this same team but with Quency: Escape Queen over xGuillo.
                However, this team can be expected to give a similar
                performance, if not a better one. xLudmilla is the major DPS for
                this boss, so buffing her seems to be the correct play in order
                to get stage 7 on this boss. However, keep in mind that, over
                time, you can get Quency:EQ’s dupes through wishlisting her.
                XGuillotine is a limited unit, so you will have to get her dupes
                right now if you want to aim for getting your title. This team
                can be considered BiS for Water Bosses with a core, with the
                Quency:EQ version giving similar performance compared to this
                team.
              </p>
              <h5>Team #2: A Crumb in Raids</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="volume" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="phantom" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="guillotine-winter-slayer"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noir" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                A team 5 example for Solo Raids. Not the best but definitely not
                the worst. In the current meta, we are left with a few units to
                use to make a team 5 for Solo Raids on Water Weak Solo Raids,
                which will essentially be filled with S~A tier DPS units.
                Quency:EQ, xGuillotine and Phantom fit the criteria very well
                here.
              </p>
              <h5>Team #3: Smile alongside the Smiling Bunny</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="volume" enablePopover />
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mari-makinami-illustrious"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="grave" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="rosanna-chic-ocean"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="phantom" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="guillotine-winter-slayer"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="alice-wonderland-bunny"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                A way to have the Water DPS units that stack their buffs get
                their stacks faster. A team for Water Weak Bosses. B.Alice
                increases stacks for xGuillo, Phantom and Quency:EQ. While this
                is not a meta team, this is a very comfortable and casual team
                that aims to increase the DPS of these DPS B3 units.
              </p>
              <h5>Team #4: A Tower that refuses to get Meta DPS</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="diesel"
                      enablePopover
                      showTreasure
                    />
                    <NikkeCharacter mode="icon" slug="marciana" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="privaty-unkind-maid"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="phantom" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="guillotine-winter-slayer"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="maiden-ice-rose"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                An Elysion Tower team for xGuillotiine. xGuillotine takes her
                time to stack up, but will buff Phantom’s attacks during her own
                Burst!
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Provides decent buffs to Water-code allies.</li>
                        <li>
                          Possesses huge innate ATK buffs, good for countering
                          bosses with high DEF stats.
                        </li>
                        <li>
                          Grants ELE DMG ▲ to herself and Water Code allies.
                        </li>
                        <li>
                          Strong use-case against Water-Weak Bosses (i.e.
                          against Fire bosses).
                        </li>
                        <li>Her design and personality is super cool!</li>
                        <li>
                          Guillotine de Mephisto is da goat for real, read the
                          event!
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          All of her buffs, for self and team, take too long to
                          fully stack.
                        </li>
                        <li>
                          No way to deal AoE damage makes her less usable in
                          Campaign.
                        </li>
                        <li>Team buffs are only for Water Code Allies.</li>
                        <li>
                          Low Burst Generation discourages her use even in
                          Elysion Tower.
                        </li>
                        <li>
                          Half of her team buffs come during her own Burst (DPS
                          units usually deal damage during their own Burst).
                        </li>
                        <li>Simply lacks DPS to compete with meta units.</li>
                        <li>Seasonal/Limited unit.</li>
                        <li>Does not scale well with ATK buffs.</li>
                        <li>
                          Ingrid refuses to make good units for Elysion Tower.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesGuilo;

export const Head: React.FC = () => (
  <Seo
    title="Guillotine: Winter Slayer Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Guillotine: Winter Slayer in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
